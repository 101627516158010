import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CModal,
  CCardHeader,
  CModalBody,
  CModalHeader,
  CRow,
  CSpinner,
  CModalTitle,
  CModalFooter,
  CContainer,
  CCol,
} from '@coreui/react'

import { logout, getMsalInstance, needAcknowledgement, getAuthToken } from 'src/common/authentication'
import Splash from 'src/views/pages/login/Splash'

const APIAuthProvider = ({children}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    const isLoggedIn = checkAuth();
    setIsAuthenticated(isLoggedIn);
    if (!isLoggedIn) {
      waitForLogin(200, 150);
    }
  }, []);

  const checkAuth = () => {
    return !!getAuthToken();
  }

  const waitForLogin = (delay, attempts) => {
    //giving auth time to complete
    attempts = attempts || 50;

    //this should never fail
    let tmpId = setInterval(() => {
      if (checkAuth()) {
        clearInterval(tmpId);
        if (needAcknowledgement()) {
          setShowAcknowledgement(true);
        } else {
          setIsAuthenticated(true);
        }
        return;
      }
      if (attempts <= 0) {
        clearInterval(tmpId);
        setErrorMsg('Unable to communicate with the OMT backend.  Please try later.');
      }
      attempts--;
    }, delay);
  }

  const acknowledgmentOK = () => {
    setShowAcknowledgement(false);
    setIsAuthenticated(true);
  }

  const declineAndExit = () => {
    logout();
    getMsalInstance().logoutRedirect();
  }

  const processAckResponse = (accepted) => {
    if (accepted) {
      acknowledgmentOK();
    } else {
      declineAndExit();
    }
  }

  return (
    <>
      {isAuthenticated && children}
      {!isAuthenticated &&
        <Splash awaitingAuth={true} error={errorMsg} showAcknowledgement={showAcknowledgement} ackResponse={processAckResponse}/>
      }
    </>
  )
}

APIAuthProvider.propTypes = {
  children: PropTypes.any,
}

export default APIAuthProvider;
