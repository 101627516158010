import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import store from './redux/store'
import { MsalProvider } from '@azure/msal-react'
import { getMsalInstance } from './common/authentication'

const msalInstance = getMsalInstance();

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

// Use the dark theme
document.documentElement.setAttribute('data-coreui-theme', 'dark');
document.documentElement.classList.add('rs-theme-dark');

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <App className="c-dark-theme" />
      </MsalProvider>
  </Provider>
)