import React, { useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow,
} from '@coreui/react'
import { loginRequest } from '../../../config/authConfig';
import PropTypes from 'prop-types'

import { ReactComponent as OWTLogo } from '../../../assets/brand/OneWeb-Technologies-Logo.svg';
import OWTWhiteLogo from 'src/assets/brand/OneWeb_Technologies_Logo_Horizontal_White.png'

import 'react-toggle/style.css'

import { useMsal } from '@azure/msal-react'


const Splash = ({error, awaitingAuth, showAcknowledgement, ackResponse, ...props}) => {
  const { instance } = useMsal();
  const [loginHint, setLoginHint] = useState(false);
  const msalInstance = instance;

  const handleLoginRedirect = () => {
    msalInstance.loginRedirect(loginRequest)
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    // Delay for 5 seconds and pop up a login hint if we haven't redirected
    if (!awaitingAuth && !showAcknowledgement) {
      setLoginHint(false);
      setTimeout(() =>{
        setLoginHint(true);
      }, 5000);
    } else {
      setLoginHint(true);
    }
  }, [awaitingAuth, showAcknowledgement]);

  const authResponse = (accepted) => {
    if (ackResponse) {
      ackResponse(accepted);
    }
  }

  return (
    <>
    <div id="background_branding_container" data-tenant-branding-background-color="true">
      <img data-tenant-branding-background="true" src="https://aadcdn.msftauthimages.net/dbd5a2dd-gkotydxenmfrt-1i9zz56qrtlaqgaryapj8mnzhb6tm/logintenantbranding/0/illustration?ts=638238344975110733" />
    </div>
    { loginHint &&
    <div className="min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <OWTLogo height={65} className='d-block mx-auto hide-when-dark' />
                  <img src={OWTWhiteLogo} height={65} className='d-block mx-auto show-when-dark' />
                  <h3 className="pt-4 mb-5 text-center text-emphasis mb-3">Online Management Tool</h3>
                  <div id="api">
                    {showAcknowledgement &&
                      <>
                      <div className="intro">
                        <h2>Consent to Monitoring</h2>
                      </div>
                      <div className="entry">
                        <p style={{textAlign: 'justify'}}>
                          By clicking OK, you acknowledge and consent to the following:<br/><br/>
                          All communications and data transiting, traveling to or from, or stored on this system will be monitored. You consent to the unrestricted monitoring, interception, recording, and searching of all communications and data transiting, traveling to or from, or stored on this system at any time and for any purpose by OneWeb Technologies and by any person or entity, including government entities, authorized by OneWeb Technologies. You also consent to the unrestricted disclosure of all communications and data transiting, traveling to or from, or stored on this system at any time and for any purpose to any person or entity, including government entities, authorized by OneWeb Technologies. You acknowledge that you have no reasonable expectation of privacy regarding your use of this system. These acknowledgments and consents cover all use of the system, including work-related use and personal use without exception.                            
                        </p>
                      </div>
                      <div className="buttons">
                        <CButton color="secondary" onClick={() => authResponse(false)}>Decline & Logout</CButton>
                        <CButton style={{marginLeft: 'auto'}} color="primary" onClick={() => authResponse(true)}>OK</CButton>
                      </div>
                      </>
                    }
                    {awaitingAuth && !showAcknowledgement && !error &&
                        <div className="intro">
                          <h2>Authenticating...</h2>
                        </div>
                    }
                    {error && 
                        <div className="intro">
                          <h2 style={{color: 'darkred'}}>{error}</h2>
                        </div>
                    }
                    {(!!error || !awaitingAuth && !showAcknowledgement) &&
                      <div className="buttons">
                        <CButton type="button" color="primary" style={{ display: 'block', margin: 'auto' }} onClick={() => handleLoginRedirect()}>Login</CButton>
                      </div>
                    }
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
    }
    </>
  )
}
Splash.propTypes = {
  error: PropTypes.any,
  awaitingAuth: PropTypes.any,
  showAcknowledgement: PropTypes.any,
  ackResponse: PropTypes.any
}

export default Splash
