const CONFIG = require('./rest-api.json')

var envType = process.env.NODE_ENV

  if (window.location.href.indexOf('dev2.') > -1) {
    CONFIG.BASE_API = CONFIG.DEV2BASE_API;
} else if (window.location.href.indexOf('dev3.') > -1) {
    CONFIG.BASE_API = CONFIG.DEV3BASE_API;
}else if (envType == 'production') {
    CONFIG.BASE_API = CONFIG.PRODBASE_API;
  }

module.exports = CONFIG;
