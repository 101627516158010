export const TIERS = [
    { name: 'PRODUCTION', label: '', pattern: "//(east\.|west\.)?omt\." },
    { name: 'DEVELOPMENT', label: 'DEVELOPMENT', pattern: "//dev\.omt\.", color: '#ffdd00', text: 'black' },
    { name: 'DEVELOPMENT2', label: 'DEVELOPMENT 2', pattern: "//dev2\.omt\.", color: '#ff9900', text: 'black' },
    { name: 'DEVELOPMENT3', label: 'DEVELOPMENT 3', pattern: "//dev3\.omt\.", color: '#ff9999', text: 'black' },
    { name: 'STAGE', label: 'STAGE', pattern: "//(test|stage)\.omt\.", color: '#009999' },
    { name: 'TRAINING', label: 'TRAINING', pattern: "//train\.omt\.", color: '#009999' },
    { name: 'LOCAL', label: 'LOCAL DEVELOPMENT', pattern: ".*", color: '#990000', text: 'black' }
];

export const detectTier = () => {
    const thisPage = window.location.href;
    for (var tier of TIERS) {
        if (thisPage.match(tier.pattern)) {
            return tier;
        }
    }
    return { name: "UNKNOWN", label: '', pattern: ".*" };
}