import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types'
import GLOBALS from '../config/rest-api';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../config/authConfig';

const AUTH_TOKEN_NAME = 'OMTauthtoken';
const AUTH_TOKEN_AZURE = 'OMTazureauthtoken';
const USER_INFO_NAME = 'OMTusername';
const USER_ACK = 'OMTuserack';
const CURR_CONTRACT_ID = 'OMTselectedcontract';
const REMEMBER_ME_NAME = 'OMTremember_me';

export const getAuthToken = () => {
    const tokenString = sessionStorage.getItem(AUTH_TOKEN_NAME);
    const userToken = JSON.parse(tokenString);
    return (!userToken?.token || userToken?.valid) ? userToken?.token : 'INVALID';
};

export const setAuthToken = (userToken) => {
    sessionStorage.setItem(AUTH_TOKEN_NAME, JSON.stringify(userToken));
};

export const getAzureAuthToken = () => {
    const tokenString = sessionStorage.getItem(AUTH_TOKEN_AZURE);
    const userToken = tokenString;
    return (!userToken) ? 'INVALID' : userToken;
};

export const setAzureAuthToken = (userToken) => {
    sessionStorage.setItem(AUTH_TOKEN_AZURE, userToken);
};

export const clearAuthToken = () => {
    sessionStorage.removeItem(AUTH_TOKEN_NAME);
};

export const getUserInfo = () => {
    const tokenString = sessionStorage.getItem(USER_INFO_NAME);
    return JSON.parse(tokenString);
};

export const setUserInfo = (userInfo) => {
    sessionStorage.setItem(USER_INFO_NAME, JSON.stringify(userInfo));
    if (userInfo.omt_contract_info && userInfo.omt_contract_info.length > 0) {
        setCurrentContractSelection(userInfo.omt_contract_info[0].CONTRACT_ID);
    }
};


export const clearUserInfo = () => {
    sessionStorage.removeItem(USER_INFO_NAME);
    sessionStorage.removeItem(USER_ACK);
};

export const getRemembered = () => {
    const info = localStorage.getItem(REMEMBER_ME_NAME);
    return JSON.parse(info);
};

export const setRemembered = (info) => {
    localStorage.setItem(REMEMBER_ME_NAME, JSON.stringify(info));
};

export const isAuthenticated = () => { const token = getAuthToken(); return token == 'INVALID' ? -1 : (token ? 1 : 0) };

export const authenticate = (user, pw) => {
    if (GLOBALS.USE_TOKEN_AUTH) {
        const data = new FormData();
        data.append('user', user);
        data.append('pw', pw);
        return fetch(`${GLOBALS.BASE_API}${GLOBALS.AUTH.LOGIN}`, {
            method: 'POST',
            body: data
        })
            .then(res => {
                if (res.status == 401) {
                    throw 'Unauthorized';
                }
                return res;
            })
            .then(res => res.json())
            .then(response => {
                if (response.error) {
                    throw response.error;
                }
                var userinfo = {
                    ...response,
                    token: undefined,
                    acceptedAcknowledgement: false
                };
                setAuthToken({ token: response.token, valid: true });
                setUserInfo(userinfo);
                return null;
            })
            .catch(err => {
                console.log(err);
                clearUserInfo();
                clearAuthToken();
                return err.toString();
            });
    } else {
        setAuthToken('validated');
        setUserInfo({});
        return Promise.resolve(null);
    }
}


export const authenticateAzureTokenWithBackend = (idToken) => {
    if (GLOBALS.USE_TOKEN_AUTH) {
        const data = new FormData();
        data.append('idToken', idToken);
        return fetch(`${GLOBALS.BASE_API}${GLOBALS.AUTH.TOKEN_LOGIN}`, {
            method: 'POST',
            body: data,
            credentials: 'include'
        })
            .then(res => {
                if (res.status == 401) {
                    throw 'Unauthorized';
                }
                return res;
            })
            .then(res => res.json())
            .then(response => {
                if (response.errtype) {
                    throw response.msg;
                }
                if (response.error) {
                    throw response.error;
                }
                var userinfo = {
                    ...response,
                    token: undefined
                };
                setAuthToken({ token: response.omt_token, valid: true });
                setUserInfo(userinfo);
                return null;
            })
            .catch(err => {
                console.log(err);
                clearUserInfo();
                clearAuthToken();
                return {
                    error: err
                }
            });
    } else {
        setAuthToken('validated');
        setUserInfo({});
        return Promise.resolve(null);
    }
}

export const logout = () => {
    // We don't want to wait too long to log out
    const logoutTimer = setTimeout(logoutCleanup, 1000);

    return authenticatedFetch(`${GLOBALS.BASE_API}${GLOBALS.AUTH.LOGOUT}`)
        .finally(() => {
            clearTimeout(logoutTimer);
            logoutCleanup();
        })
}

export const logoutCleanup = () => {
    clearUserInfo();
    clearAuthToken();
    sessionStorage.clear(); //clearing everything from session
}

var alreadyLoggingOut = false;
export const authenticatedFetch = (url, options) => {
    if (!options) {
        options = {};
    }
    if (!options.headers) {
        options.headers = {};
    }
    options.currPage = window.location.href;
    options.redirect = 'follow';
    options.credentials = 'include'
    if (GLOBALS.USE_TOKEN_AUTH) {
        options.headers['Authorization'] = 'Bearer ' + getAuthToken();
    }
    return fetch(url, options).then(resp => {
        if (resp.redirected) {
            var newUrl = resp.url.substring(0, resp.url.indexOf("="));
            window.location.href = newUrl + "=" + encodeURIComponent(options.currPage);
            return;
        } else if (resp.status == 401) {
            resp.text()
                .then(txt => {
                    console.log("401: " + JSON.stringify(txt));
                    if (txt && txt.indexOf("current role") != -1) {
                        alert(`Unauthorized Action.`);
                    } else if (!alreadyLoggingOut) {
                        // This will be reset once the login process leads to a full app reload
                        alreadyLoggingOut = true;
                        alert(`An authentication error has occured. Please try again.`);
                        getMsalInstance().logoutRedirect();
                        logoutCleanup();
                    }
                })
                .catch(err => {
                    alert(`An authentication error has occured. Please try again.`);
                });
            return;
        } else if (resp.status == 403) {
            alert(`You have exceeded the timeout and have been logged out of OMT`);
            getMsalInstance().logoutRedirect();
            logoutCleanup();
            return;
        } else if (resp.status == 500 || resp.status == 400 || resp.status == 404) {
            return resp.json().then(payload => {
                if (payload?.error) {
                    throw payload.error;
                } else {
                    throw resp.statusText;
                }
            })
        } else {
            const allCookies = document.cookie;
            const cookiesArray = allCookies.split('; ');
            const cookie = cookiesArray.find(cookie => cookie.startsWith('timeoutCookie'));
            const cookieValue = cookie ? cookie.split('=')[1] : null;
            return resp;
        }
    }).catch(e => {
        console.log(e);
        throw e;
    });
}

export const hasPermission = (wantedPermission) => {
    const wildcardPermission = wantedPermission.replace(/:.*$/, ':*');
    const perms = getUserInfo()?.permissions || [];
    return perms.some(perm => perm == wantedPermission || perm == wildcardPermission);
}

export const setCurrentContractSelection = (contractId) => {
    sessionStorage.setItem(CURR_CONTRACT_ID, contractId);
};

export const getCurrentContractSelection = () => {
    const contractId = sessionStorage.getItem(CURR_CONTRACT_ID);
    return contractId;
};

export const getContracts = () => {
    const contracts = getUserInfo()?.omt_contract_info;
    console.log("get_contract: " + JSON.stringify(contracts));
    return contracts;
};

export const getMsalInstance = (errorSetter) => {
    const msalInstance = new PublicClientApplication(msalConfig);

    msalInstance.addEventCallback(async (event) => {
        if (
            (event.eventType === EventType.LOGIN_SUCCESS ||
                event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                event.eventType === EventType.SSO_SILENT_SUCCESS) &&
            event.payload.account
        ) {
            const accountInfo = event.payload.account;
            //console.log("msal callback...: " + event.eventType);
            msalInstance.setActiveAccount(accountInfo);
            //console.log(accountInfo);

            // Do not reauthenticate a token if we are just refreshing the page
            if (getAuthToken()) {
                return;
            }

            clearAuthToken();
            const res = await authenticateAzureTokenWithBackend(event.payload.idToken);
            if (res && res.error) {
                try {
                    if (res.error) {
                        console.log("accnt: " + JSON.stringify(accountInfo));
                        alert(res.error);
                        msalInstance.logoutRedirect({
                            account: accountInfo
                        });
                    }
                } catch {
                    //do anything?
                }
            }
        }
    });
    return msalInstance;
}

export const acceptAcknowledgment = () => {
    sessionStorage.setItem(USER_ACK, true);
}

export const needAcknowledgement = () => {
    const ack = sessionStorage.getItem(USER_ACK);
    if (ack == null) {
        return true;
    } else {
        return !ack;
    }
}

export const isLocalUser = () => {
    try {
        const usr = getUserInfo();
        return usr.local_user;
    } catch (err) {
        return false;
    }
}


//role-related checks

const ADMIN_USER_ROLE = 'OWT'//Admin

const OWT_FIN_ROLE = 'OWT-FIN'//Finance
const OWT_OPR_ROLE = 'OWT-OPR'//Operations
const OWT_SEC_ROLE = 'OWT-SEC'//Security
const OWT_CON_ROLE = 'OWT-CON'//Contract Admin
const OWT_ENG_ROLE = 'OWT-ENG'//Engineering
const OWT_EXEC_ROLE = 'OWT-EXEC'//Executive

const EC_EXEC_ROLE = 'EC-EXEC'//End-Customer Executive
const EC_OPR_ROLE = 'EC-OPR'//End-Customer Operations
const EC_CON_ROLE = 'EC-CON'//End-Customer Contract Admin

export const isOWT = () => {
    try {
        const usr = getUserInfo();
        return usr.omt_role.indexOf('OWT') != -1;
    } catch (err) {
        return false;
    }
}

export const isEndCustomer = () => {
    try {
        const usr = getUserInfo();
        return usr.omt_role.indexOf('EC') != -1;
    } catch (err) {
        return false;
    }
}

export const isAdmin = () => {
    try {
        const usr = getUserInfo();
        return usr.omt_role == ADMIN_USER_ROLE;
    } catch (err) {
        return false;
    }
}

export const isEcUser = () => {
    try {
        return isEndCustomer();
    } catch (err) {
        return false;
    }
}

export const isEcExec = () => {
    try {
        const usr = getUserInfo();
        return usr.omt_role == EC_EXEC_ROLE;
    } catch (err) {
        return false;
    }
}

export const isEcOperations = () => {
    try {
        const usr = getUserInfo();
        return usr.omt_role == EC_OPR_ROLE;
    } catch (err) {
        return false;
    }
}

export const isEcAllContractAccess = () => {
    try {
        const usrContracts = getContracts();
        return usrContracts != null && usrContracts.filter(c => c.NAME == 'All contracts').length == 1;
    } catch (err) {
        return false;
    }
}

export const isEcContractAdmin = () => {
    try {
        const usr = getUserInfo();
        return usr.omt_role == EC_CON_ROLE;
    } catch (err) {
        return false;
    }
}

export const isOwtExec = () => {
    try {
        const usr = getUserInfo();
        return usr.omt_role == OWT_EXEC_ROLE;
    } catch (err) {
        return false;
    }
}

export const isOwtOperations = () => {
    try {
        const usr = getUserInfo();
        return usr.omt_role == OWT_OPR_ROLE;
    } catch (err) {
        return false;
    }
}

export const isOwtEngineering = () => {
    try {
        const usr = getUserInfo();
        return usr.omt_role == OWT_ENG_ROLE;
    } catch (err) {
        return false;
    }
}

export const isOwtFinance = () => {
    try {
        const usr = getUserInfo();
        return usr.omt_role == OWT_FIN_ROLE;
    } catch (err) {
        return false;
    }
}

export const isOwtSecurity = () => {
    try {
        const usr = getUserInfo();
        return usr.omt_role == OWT_SEC_ROLE;
    } catch (err) {
        return false;
    }
}

export const isOwtContractAdmin = () => {
    try {
        const usr = getUserInfo();
        return usr.omt_role == OWT_CON_ROLE;
    } catch (err) {
        return false;
    }
}