import React, { Component, Suspense, useState } from 'react'
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import './scss/style.scss'
import PropTypes from 'prop-types'
import { MsalProvider, AuthenticatedTemplate, useMsalAuthentication, UnauthenticatedTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser'
import APIAuthProvider from './components/APIAuthProvider';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const LoginNew = React.lazy(() => import('./views/pages/login/Login_AZ'))
const Splash = React.lazy(() => import('./views/pages/login/Splash'))
const Logout = React.lazy(() => import('./views/pages/login/Logout'))
const Page401 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))


const App = () => {
  useMsalAuthentication(InteractionType.Redirect);

  return (
    <>
      <APIAuthProvider>
        <BrowserRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </APIAuthProvider>   
      <UnauthenticatedTemplate>
         <Splash />
      </UnauthenticatedTemplate>
    </>
  );
};
App.propTypes = {
  msalInstance: PropTypes.any
}
export default App
